@import "abstracts/_abstracts";
.HomeBenefitPointItem {
    width: calc((100% - 2 * var(--spaceXl)) / 3);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @include media('<=sm') {
        width: 100%;
        max-width: 30em;
    }

    &-image {
        display: none;

        svg, img {
            width: auto;
            height: 4em;
            color: var(--colorBrand);
        }
    }

    &-title {
        font-weight: 800;
        font-size: clamp(3rem, 7vw, 5rem);
        line-height: 1;
        color: var(--colorBrandLighter);
        -webkit-text-stroke: 2px text-contrast(var(--colorBrand));

        &:last-child {
            margin-bottom: 0;
        }

        a {
            color: inherit;
        }
    }

    &-text {
        font-size: var(--textSm);
    }
}